@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
  // background-color: rgb(250, 249, 250) !important;
}

.text-field-tooltip-link:hover { // only viable place to put this style
  opacity: 0.8 !important;
  transition: opacity 0.1s !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn:focus {
  box-shadow: none;
}

.custom-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .custom-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .custom-container {
    max-width: 1100px;
  }
}

@media (min-width: 1350px) {
  .custom-container {
    max-width: 1350px;
  }
}
