.coin-selector-container {
    font-weight: bold;

    .inner-container {
        height: 36px;
        cursor: pointer;
        .coin-logo {
            width: 32px;
            margin-right: 0.5rem !important;
            margin-bottom: 4px;
            border-radius: 50%;
        }

        .coin-selector-button {
            border-radius: 16px;
            padding: 5px;
        }
    }

    .inner-container:hover {
        opacity: 0.8;
        transition: opacity 0.1s;
    }
}

.coin-selector-modal {
    .modal-header {
        font-weight: bold;
        font-size: 20px;
        padding: 12px 24px;
    }

    .modal-body {
        padding-top: 0px;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0px;

        .text-field-wrapper {
            padding: 24px;
        }

        .coin-list {
            height: 390px;
            overflow-y: scroll;

              /* width */
            &::-webkit-scrollbar {
                width: 5px;
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
                border-radius: 10px;
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 10px;
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #555; 
            }

            .no-results-found {
                font-size: 16px;
                color: grey;
            }
        }
    }
}

@media (min-width: 350px) {
    .coin-selector-modal {
        width: 322px;
    }
}


@media (min-width: 600px) {
    .coin-selector-modal {
        width: 420px;
    }
}