.navbar-container {
    font-size: 16px;
    margin-bottom: 40px;
    background-color: white;

    .logo {
        width: 40px;
    }

    .brand {
        font-family: "Neo Sans Std";
        padding-top: 0.3125rem;
        padding-bottom: 0.3125rem;
        margin-right: 1rem;
        font-size: 1.25rem;
        text-decoration: none;
        white-space: nowrap;
    }

    .navbar-toggler:focus,
    .navbar-toggler:active,
    .navbar-toggler-icon:focus {
        outline: none;
        box-shadow: none;
    }

    .navbar-item {
        margin-right: 8px;
        cursor: pointer;
    }
}

.navbar > .custom-container {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.settings-modal {
    .modal-header {
        font-weight: bold;
        font-size: 20px;
        padding: 12px 24px;
    }

    .modal-body {
        padding-top: 12px;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 12px;
    }
}

@media (min-width: 350px) {
    .settings-modal {
        width: 322px;
    }
}


@media (min-width: 600px) {
    .settings-modal {
        width: 420px;
    }
}