.rank-table-container {
    .table-wrapper {
        border: 1px solid rgb(231, 227, 235);
        // padding: 10px;
        background-color: white;
        font-size: 14px;
        margin-bottom: 40px;
        overflow-y: scroll;

        // https://stackoverflow.com/questions/53499803/make-table-header-and-first-two-columns-fixed
        td:first-child, th:first-child {
            position:sticky;
            left:0;
            z-index:1;
            background-color:white;
          }
          td:nth-child(2),th:nth-child(2)  { 
            position:sticky;
            left:50.64px;
            z-index:1;
            background-color:white;
            }
          .th {
            position: sticky;
            top: 0;
            background-color: white;
            z-index:2;
            color: black;
          }
          th:first-child , th:nth-child(2) {
            z-index:3
            }

        // .rank-table-row {
        //     border-bottom: 1px solid rgb(231, 227, 235);
        // }

        /* width */
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 10px;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555; 
        }

        // .header {
        //     padding: 10px;
        // }


        .paginator-wrapper {
            .pagination {
                width: 190px;
                .paginator-btn {
                    background-color: transparent;
                    border: 0;
                    font-size: 12px;
                }
            }
        }
    }
}

@media (min-width: 100px) {
    .table-wrapper {
        min-height: 500px;
        width:100vw;
        overflow-x: scroll;
    }
}

@media (max-width: 600px) {
    .pagination {
        margin-top: 5px;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }
}

@media (min-width: 600px) {
    .table-wrapper {
        width:100%;
        border-radius: 16px;
        overflow-x: hidden;
    }
}

@media (min-width: 990px) {
    .table-wrapper {
        height: 85vh;
    }
}