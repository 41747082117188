.advanced-input-container {
    .input-name-wrapper {
        font-size: 15px;
        color: grey;
        margin-left: 8px;
        font-weight: 600;
    }

    .input-wrapper {
        height: 55px;
        padding: 10px;
        background-color: #F3F5F7;
        border-radius: 16px;

        .input-name {
            font-size: 12px;
            padding-left: 10px;
        }

    }

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}