.home-container {
    .rank-table-wrapper {
        flex: 1;
    }
}

@media (min-width:600px) and (max-width:990px) {
    .rank-table-wrapper {
        width: 85vw;
    }
}

@media (min-width: 990px) {
    .calculator-wrapper {
        margin-right: 50px;
    }
}