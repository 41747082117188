.coin-selector-item-container {
    height: 56px;
    padding-left: 24px;

    .coin-icon {
        width: 24px;
        height: 24px;
        margin-right: 0.5rem !important;
        margin-bottom: 4px;
        border-radius: 50%
    }
    .coin-info-wrapper {
        height: 48px;
        .coin-name {
            font-size: 14px;
        }
    
        .coin-symbol {
            font-weight: 400;
            font-size: 12px;
            color: grey;
        }
    }
}

.coin-selector-item-container:hover {
    cursor: pointer;
    background-color: #FAF9FA;
}

.coin-selector-item-container-disabled {
    cursor: context-menu !important;
    background-color: white !important;
    opacity: 0.5;
}