.mc-container {
    border-radius: 24px;
    width: 300px;
    margin-bottom: 70px;
}

.calc-view {
    .calc-header {
        text-align: center;
        padding: 16px;
        .calulate-heading {
            font-size: 20px;
            font-weight: bolder;
        }
        .instructions {
            color:grey;
            font-size: 13px;
        }
    }

    .body {
        padding: 12px;
        .coin-select-wrapper {
            text-align: center;
            margin-bottom: 12px;
        }

        .symbol-wrapper {
            .symbol {
                border-radius: 50%;
                height: 32px;
                width: 32px;
                font-weight: 500;
                font-size: 24px;
            }
        }

        .inputs {
            input{
                border:0;
            }
            input:focus{
                outline: none;
            }
        }

        .calc-btn {
            width: 100%;
            background-color: #3476E4;
            color: white;
            font-weight: bold;
            border: 0;
            border-radius: 16px;
            height: 45px;
        }

        .calc-btn:hover {
            opacity: 0.8;
            transition: opacity 0.1s;
        }

        .calc-btn:disabled,
        .calc-btn[disabled]{
            background-color: rgb(233, 234, 235);;
            color: rgb(189, 194, 196);;
            cursor: not-allowed;
        }
    }  
}


.stats-view {
    .header {
        padding-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
        .heading {
            font-size: 20px;
            font-weight: bolder;

            .coin-logo {
                width: 28px;
                height: 28px;
                margin-right: 0.5rem !important;
                margin-bottom: 4px;
                border-radius: 50%;
            }
        }
        .sub-heading {
            color:grey;
            font-size: 13px;
        }
    }

    .stats {
        padding: 16px;
        border-radius: 12px;
    
        .heading {
            margin-bottom: 10px;
            font-weight: 500;
        }
    
        .stat {
            margin-bottom: 10px;
    
            .stat-label {
                font-size: 15px;
                font-weight: 600;
                color: grey;
            }
        }

        .last-stat {  
            .stat-label {
                font-size: 15px;
                font-weight: 600;
                color: grey;
            }
        }
    }

    .end-section {
        color: grey;
        font-size: 13px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
    }
}

.stat-data-positive {
    color: #0cc182;
}

.stat-data-negative {
    color: #ea3943;
}