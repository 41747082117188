.forecast-stat-container {
    .stat-label {
        font-size: 15px;
        font-weight: 600;
        color: grey;
    }

    .actual-stat {
        font-weight: 600;
        font-size: 20px;
    }
}

.stat-data-positive {
    color: #0cc182;
}

.stat-data-negative {
    color: #ea3943;
}