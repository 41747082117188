.calc-input-container {
    .input-name-wrapper {
        .input-name {
            font-weight: bold;
        }
        .info-icon-wrapper {
            padding-right: 10px;
            padding-bottom: 10px;
            padding-top: 10px;
            
            .info-icon {
                color: #A6B1C2;
                font-size: 16px;
                margin-left: 5px;
            }
        }
    }

    .input-wrapper {
        height: 45px;
        padding: 10px;
        background-color: #F2F2F2;
        border-radius: 16px;

        .input {
            width: 100%;
            background-color: #F2F2F2;
            border: 0;
            -webkit-appearance: none;
        }
        
        .input:focus {
            outline: none;
        }
    }

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}